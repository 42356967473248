import React, { useEffect, useState } from 'react';
import { Backdrop, Grid, Fade, Typography } from '@mui/material';

const DeploymentSuccessModal = ({ showAppDeployed, appTime, onClose }) => {
  const [gifLoaded, setGifLoaded] = useState(false);

  // Automatically close after GIF completes
  useEffect(() => {
    if (showAppDeployed && gifLoaded) {
      // Close the modal after the GIF completes. Adjust time to match GIF duration
      const gifDuration = 2020; // Adjust this time to match the GIF's length
      const timer = setTimeout(() => {
        onClose(); // Trigger the parent function to close the modal
      }, gifDuration);

      // Cleanup the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [showAppDeployed, gifLoaded, onClose]);

  // Handle GIF load event to set the gifLoaded state
  const handleGifLoad = () => {
    setGifLoaded(true);
  };

  return (
    showAppDeployed && (
      <Backdrop sx={{ bgcolor: "rgba(0, 0, 0, 0.87)", color: "white" }} open={true}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <img
              src={"/deployed.gif"}
              alt="deployed"
              style={{
                width: "100%",
                height: "200px",
                minWidth: "100px",
                marginBottom: "5px",
                maxWidth: "800px",
              }}
              onLoad={handleGifLoad} // Set gifLoaded to true once the GIF has loaded
            />
          </Grid>
          <Grid item>
            <Fade in={true}>
              <Typography variant="h1" sx={{ fontSize: "2rem", fontWeight: "bold" }}>
                Deployed Successfully in <span style={{ color: "#2dd881" }}>{appTime}</span>! 🎉
              </Typography>
            </Fade>
          </Grid>
        </Grid>
      </Backdrop>
    )
  );
};

export default DeploymentSuccessModal;
