import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CheckCircle, Close, HelpOutline } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Alert, AlertTitle, AppBar, Autocomplete, Box, Button, Chip, CircularProgress, Container, Fab, Fade, Grid, IconButton, InputAdornment, LinearProgress, Paper, Skeleton, Snackbar, TextField, Toolbar, Tooltip, Typography, Zoom } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { FaDatabase } from 'react-icons/fa';
import { GrClose } from 'react-icons/gr';
import { IoIosPeople, IoMdPeople } from 'react-icons/io';
import { RiAddLine } from 'react-icons/ri';
import { VscCheck } from 'react-icons/vsc';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import UpgradePlan from '../../Containers/UpgradePlan';
import LogTrigger from '../../LogTrigger';
import { HISTORY } from '../Deployments/Activity/ActivityTable';
import { APP_LOCATION_LIST } from '../Deployments/Deploy/Main';
import { APP_LIST } from '../Home/Apps';
import { ADD_NEW_APP } from '../Home/CreateNewApp/Main';
import { ADD_VOLUME, CONFIG_DEFINITION, DEPLOY_IMAGE, UPDATE_APP } from '../Home/CreateNewApp/StepTwo';
import { APPS } from '../Home/DeploymentTable';
import UserDetailsTooltip from '../Home/UserDetailsTooltip';
import EmptyPageRenderCustom from '../NewPages/EmptyPageRenderCustom';
import { USER_ACTIVITY } from '../Settings/Nav';
import { ALL_ORG_LIST } from '../Settings/Organization';
import { BiSolidDownArrow } from 'react-icons/bi';
import { MdModeEdit } from 'react-icons/md';
import DeploymentSuccessModal from '../../Containers/DeployBackdrop';

export const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



export const CHECK_DB_ADD = gql`
{
    AppsCount {
      totalApps
    }
  }`

function useSearchQuery() {
    return new URLSearchParams(useLocation().search);
}

export function millisToMinutesAndSeconds(millis) {
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return (seconds < 10 ? '0' : '') + seconds;
}
const columns = [
    {
        field: "name",
        headerName: "Name",
        width: 200,
        align: "left",
        renderCell: (cellValues) => {
            return (<span style={{ textTransform: "capitalize" }}> <IconButton style={{ padding: "0px 0px 0px 0px", marginRight: "10px" }} ><FaDatabase color='primary' style={{ fontSize: "18px" }} /></IconButton> {cellValues.value}

            </span>)
        }


    },
    {
        field: "status",
        headerName: "Status",
        align: "center",
        renderCell: (cellValues) => {
            if (cellValues.value == "Active") {
                return (<div style={{ color: "green", background: "rgb(229, 252, 235)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
            }

            if (cellValues.value == "New") {
                return (<div style={{ color: "rgb(33, 150, 243)", background: "rgb(227, 242, 253)", borderRadius: "24px", padding: "0px 10px 0px 10px", border: "1px green " }} > {cellValues.value}</div>)
            }
        }
    },
    { field: "version", headerName: "Version", width: 100, align: "center" },
    {
        field: "deployed",
        headerName: "Deployed",

        align: "center",
        renderCell: (cellValues) => {
            return (
                <div>
                    {cellValues.value == false ? (<GrClose style={{ color: "gray", fontSize: "15px" }} />)
                        : (<VscCheck style={{ color: "green", fontSize: "20px" }} />)}
                </div>);
        }
    },
    {
        field: "organization", headerName: "Organization", width: 150, align: "center",
        renderCell: (cellValues) => {
            return (<div style={{ color: "#3d5066", textTransform: "capitalize" }}><IconButton style={{ padding: "0px", margin: "0px 5px 0px 5px" }}>{cellValues.value.slug === "zoro57" ? <IoMdPeople style={{ fontSize: "18px" }} /> : <IoIosPeople style={{ fontSize: "18px" }} />}</IconButton>{cellValues.value.slug}</div>)
        }
    },
    {
        field: "userDetails",
        headerName: "Created by",
        width: 170,
        align: "left",
        renderCell: (cellValues) => {
            if (cellValues.value) {
                return (
                    <UserDetailsTooltip data={cellValues.value}>
                    </UserDetailsTooltip>);
            }
            else {
                return "No info"
            }

        }
    },
    {
        field: "imageName", headerName: "Db Type", width: 100, align: "center", renderCell: (cellValues) => {
            return (
                <>
                    {cellValues.value.includes("mysql") && <img style={{ objectFit: "contain", aspectRatio: "1/1", width: "100px", height: "40px" }} src={"https://upload.wikimedia.org/wikipedia/fr/6/62/MySQL.svg"} />}
                    {cellValues.value.includes("postgres") && <img style={{ objectFit: "contain", aspectRatio: "1/1", width: "100px", height: "40px" }} src={"https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg"} />}
                    {cellValues.value.includes("mongo") && <img style={{ objectFit: "contain", aspectRatio: "1/1", width: "100px", height: "40px" }} src={"https://upload.wikimedia.org/wikipedia/en/5/5a/MongoDB_Fores-Green.svg"} />}
                    {cellValues.value.includes("maria") && <img style={{ objectFit: "contain", aspectRatio: "1/1", width: "100px", height: "40px" }} src={"https://upload.wikimedia.org/wikipedia/commons/c/ca/MariaDB_colour_logo.svg"} />}
                    {cellValues.value.includes("redis") && <img style={{ objectFit: "contain", aspectRatio: "1/1", width: "100px", height: "40px" }} src={"https://upload.wikimedia.org/wikipedia/commons/6/64/Logo-redis.svg"} />}

                </>
            )
        }
    },
    {
        field: "createdAt", headerName: "Created At", align: "right", renderCell: (cellValues) => {
            var date = moment(new Date(cellValues.row.createdAt), "YYYY MM DD hh:mm:ss").fromNow();
            return <div style={{ color: "grey" }}> {date}</div>
        }
    },


];
export default function DatabaseTable() {
    const [selectedDB, setselectedDB] = useState("mysql");
    const [searched, setSearched] = React.useState("")
    const [rows, setRows] = React.useState([])
    const [row, setrow] = React.useState([])
    const theme = useTheme();

    const createNewDatabase = useSearchQuery().get("create")
    const [value, setValue] = React.useState(row[0]);
    const [inputValue, setInputValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [dbName, setdbName] = React.useState("");
    const [dbPass, setdbPass] = React.useState("");
    const [dbUserName, setdbUserName] = React.useState("root");
    const [startLoading, setstartLoading] = React.useState(false);
    const [beforeTime, setbeforeTime] = React.useState("")
    const [time, setTime] = React.useState("")
    const [volumePath, setvolumePath] = useState(`/data/dbname`);
    const [volumeSize, setvolumeSize] = useState("1");
    const [volumeSizeLimit, setvolumeSizeLimit] = useState(10);
    const [volumeSizeError, setvolumeSizeError] = useState(false);
    const [showLimitReached, setshowLimitReached] = useState(false);
    const [showAdditionalDetails, setshowAdditionalDetails] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const generateRandomName = (dbName) => {
        const maxLength = 14;
        const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        let randomString = '';

        // Calculate the number of characters needed after the dbName and hyphen
        const remainingLength = maxLength - dbName.length - 1; // subtract 1 for hyphen

        if (remainingLength < 1) {
            return 'Invalid input';
        }

        for (let i = 0; i < remainingLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            randomString += characters[randomIndex];
        }

        return `${dbName}-${randomString}`;
    };


    const handleResetAll = () => {
        var tempName = generateRandomName(selectedDB) //`${superb.random()}${Math.floor(1000 + Math.random() * 9000)}`.substring(0, 15)
        setdbName(tempName)
        setvolumePath(`/data/${tempName}`)
        setdbPass("")
        setdbUserName("root")
        setValue(row[0])
        setInputValue("")
        setstartLoading(false)
        setshowAdditionalDetails(false)
    }
    var definitionMap = new Map();

    const handleClickOpen = () => {
        setOpen(true);
        setshowLimitReached(JSON.parse(localStorage.getItem("access")).apps === parseInt(datacheck && datacheck.AppsCount.totalApps))
        handleResetAll()
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [updateApp, { data: updateData, loading: updateLoad, error: updateErr }] = useMutation(UPDATE_APP, {
        refetchQueries: [CONFIG_DEFINITION],
        onCompleted: () => {
            addVolume({
                variables: {
                    appId: dbName,
                    name: dbName,
                    size: volumeSize.toString(),
                    path: volumePath
                }
            })
        },
        onError: (err) => {
            handleResetAll()
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    });


    // const handleShowDatabaseDeployed = () => {
    //     handleClose()
    // }

    const [showAppDeployed, setshowAppDeployed] = useState(false);

    const closeModal = () => {
        setshowAppDeployed(false); // Close the modal after 5 seconds
        setOpenAlert(true)
        handleClose()
    };

    const [deployImage, { data: DPData, loading: DPLoad, error: DPErr }] = useMutation(DEPLOY_IMAGE, {
        refetchQueries: [APPS, APP_LIST, APP_LOCATION_LIST, USER_ACTIVITY, HISTORY],
        onCompleted: (data) => {
            handleResetAll()
        
            setshowAppDeployed(true)
        
            var time = new Date()
            setTime(time - beforeTime)
        },
        onError: (err) => {
            handleResetAll()
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        }
    })

    const [addVolume, { loading: volumeLoad }] = useMutation(ADD_VOLUME, {
        onCompleted: () => {
            getCongif({ variables: { name: dbName } })

        }
    });

    const [getCongif, { loading: resload, error: reserr, data: appdres }] = useLazyQuery(CONFIG_DEFINITION, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            if (data.app.config) {
                definitionMap.set('definition', data.app.config.definition)
            }

            var tempimage = ""
            var tempenv = []
            if (selectedDB === "mysql") {
                tempenv = [`MYSQL_ROOT_PASSWORD=${dbPass}`]
                tempimage = "mysql:latest"
            }

            if (selectedDB === "MariaDB") {
                tempenv = [`MYSQL_ROOT_PASSWORD=${dbPass}`]
                tempimage = "mariadb:latest"
            }

            if (selectedDB === "MongoDB") {
                tempenv = [`MONGO_INITDB_ROOT_USERNAME=${dbUserName}`, `MONGO_INITDB_ROOT_PASSWORD=${dbPass}`]
                tempimage = "mongo:latest"
            }

            if (selectedDB === "Redis") {
                tempenv = [`REDIS_PASSWORD=${dbPass}`]
                tempimage = "redis:latest"
            }


            if (selectedDB === "postgres") {
                tempenv = [`POSTGRES_PASSWORD=${dbPass}`]
                tempimage = "postgres:latest"
            }
            deployImage({ variables: { appId: dbName, image: tempimage, definition: definitionMap.get("definition"), envMapArgs: tempenv, envArgs: "", archiveUrl: "" } });








        }
    });

    const [addApp, { data: appdata, loading: apploading, error: apperror }] = useMutation(ADD_NEW_APP, {
        refetchQueries: [APPS, APP_LIST],

        onError: (err) => {
            handleResetAll()
            enqueueSnackbar(err.message, {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
            })
        },
        onCompleted: () => {

            var tempimage = ""
            var tempInternalPort = "3306"
            var tempExternalPort = "3306"

            if (selectedDB === "mysql") {
                tempimage = "mysql:latest"
                tempInternalPort = "3306"
                tempExternalPort = "3306"
            }

            if (selectedDB === "Redis") {
                tempimage = "redis:latest"
                tempInternalPort = "6379"
                tempExternalPort = "6379"
            }

            if (selectedDB === "postgres") {
                tempimage = "postgres:latest"
                tempInternalPort = "5432"
                tempExternalPort = "5432"
            }
            if (selectedDB === "MariaDB") {
                tempimage = "mariadb:latest"
                tempInternalPort = "3306"
                tempExternalPort = "3306"
            }
            if (selectedDB === "MongoDB") {
                tempimage = "mongo:latest"
                tempInternalPort = "27017"
                tempExternalPort = "27017"
            }

            updateApp({
                variables: {
                    appId: dbName,
                    build: `{"image": "${tempimage}", "builder": "DB", "builtin": "${selectedDB}"}`,
                    replicas: 1,
                    routingPolicy: "Latency",
                    resource: `{"RequestRequirement":{"cpu":"","Memory":""},"LimitRequirement":{"cpu":"","Memory":""}}`,
                    internalPort: tempInternalPort,
                    externalPort: tempExternalPort
                }
            })
        }
    });






    const handleCreateDBapp = () => {
        setstartLoading(true)
        if (value.parentId) {
            //sub org
            addApp({ variables: { name: dbName, organizationId: value.parentId, businessUnitId: "", subOrganizationId: value.id, workloadManagementId: "" } });
            // props.isError({ 'orgid': value.parentId, 'suborg': value.id, 'buiid': "" }, e,[value.parentOrgName,value.name])
        }
        else {
            if (value.region) {
                //org
                addApp({ variables: { name: dbName, organizationId: value.id, businessUnitId: "", subOrganizationId: "", workloadManagementId: "" } });
                // props.isError({ 'orgid': value.id, 'suborg': "", 'buiid': "" }, e,[value.name])
            }
            else {
                // bu
                addApp({ variables: { name: dbName, organizationId: value.orgId, businessUnitId: value.id, subOrganizationId: value.subOrgId, workloadManagementId: "" } });
                // props.isError({ 'orgid': value.orgId, 'suborg': value.subOrgId, 'buiid': value.id }, e,[value.orgName,value.subOrgName,value.name])
            }
        }

        var CryptoJS = require("crypto-js");


    }

    const { loading: loadorg, error: errorg, data: orgdata, refetch: refetchorg } = useQuery(ALL_ORG_LIST, {
        variables: { userId: localStorage.getItem("userId") },
    }
    );

    const { loading: loadcheck, error: errcheck, data: datacheck, refetch: refetchcheck } = useQuery(CHECK_DB_ADD);



    const [nameValidation, setNameValidation] = React.useState(false)
    const valuevalidateName = (e) => {
        var regexp = /^([a-z0-9_](?:(?:[a-z0-9_-]|(?:(?!))){0,13}(?:[a-z0-9]))?)$/
        const checkvalue = regexp.test(e.target.value)
        if (checkvalue.length > 0) {
            setNameValidation(false)
        }
        setNameValidation((checkvalue) ? false : true)
    }

    let history = useHistory()




    const handleCellClick = (param, event) => {

        if (param.colDef.field !== "action") {
            if (param.colDef.field !== "hostname") {
                history.push(`/database/${param.row.name}`)
            }
        }

        if (param.colIndex === 2) {
            event.stopPropagation();
        }

    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };


    const { loading, error, data, refetch } = useQuery(APP_LIST, { variables: ({ region: "", orgSlug: "" }) });
    useEffect(() => {
        if (localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName === "Enterprise") {
            setvolumeSizeLimit(15)
        }
        if (localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName === "Starter") {
            setvolumeSizeLimit(5)
        }

        refetch()
        refetchorg()
        refetchcheck()

        if (orgdata) {

            if (orgdata.organizationsandBusinessUnit.nodes) {
                setrow([])
                orgdata.organizationsandBusinessUnit.nodes.forEach(element => {
                    if (element.parentId === "") {
                        setrow(row => [...row, element])
                    }
                })
            }

        }

        if (createNewDatabase) {
            handleClickOpen()
            history.push("/databases")
        }

        if (!error && !loading && data.apps.nodes) {
            setRows(
                data.apps.nodes.filter((e) => {
                    return (e.imageName && e.imageName.includes("mysql") || e.imageName && e.imageName.includes("maria") || e.imageName && e.imageName.includes("redis") || e.imageName && e.imageName.includes("mongo") || e.imageName && e.imageName.includes("postgres")) && e.builtinType !== "FinOps"

                })
            );
        }
    }, [data, orgdata]);

    useEffect(() => {
        
    }, [showAppDeployed]);

    if (error) return `Error! ${error.message}`;
    if (error) {
        LogTrigger(error.message, "error", window.location.href, "Apps")
    }

    if (data) {
        LogTrigger(`All Apps length - ${data.apps.nodes && data.apps.nodes.length}`, "info", window.location.href, "Apps")
    }

    return (
        <div>


            {DPData && DPData.deployImage.release && <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',

                }}
                open={openAlert} onClose={handleCloseAlert}>
                <Alert icon={<CheckCircle fontSize="inherit" />} elevation={3} onClose={handleCloseAlert} severity={"success"} style={{ textAlign: "left" }} >
                    <AlertTitle>Deployed Successfully in {millisToMinutesAndSeconds(time)} seconds🎉</AlertTitle>

                    <p style={{ color: "grey", fontSize: "smaller", marginTop: "10px" }}>
                        <IconButton style={{ padding: "0px" }} size="large">
                            <HelpOutline style={{ fontSize: "15px" }} />
                        </IconButton>
                        App can take 90 seconds to be accessible, <a href='https://docs.nife.io/docs/Guides/Deployment%20Time' target='_blank'>Read More</a></p>
                        {true && <Box sx={{ mt: 2, p: 1.5, fontSize: "12px", fontWeight: "bold", borderRadius: "12px", background: "linear-gradient(to bottom, #e9f2ff, #c1d9f9)", color: "#0c66e4" }}>
                            <sapn style={{ fontSize: "1rem" }}>  🚦</sapn>
                            <span> High traffic detected. If app did not work try after some time. Thank you for your patience.</span>
                        </Box>}
                </Alert>
            </Snackbar>}
        
            <Dialog
                open={open}
                fullScreen
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {!showAppDeployed && <AppBar sx={{ background: "#f5f5f5", color: "#000", boxShadow: "none" }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large">
                            <Close />
                        </IconButton>
                        <Typography sx={{ flex: 1, }}>
                            Create New Database
                        </Typography>
                    </Toolbar>
                    {loadorg && <LinearProgress />}
                </AppBar>}
                <DeploymentSuccessModal
                showAppDeployed={showAppDeployed}
                appTime={`${millisToMinutesAndSeconds(time)} seconds`} 
                onClose={closeModal}
            />

                {!loadcheck && datacheck && localStorage.getItem("access") &&
                    <>
                        {showLimitReached ?
                            <Container sx={{ mt: 10 }}>

                                <Grid
                                    container
                                    direction="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <h2>You've reached your maximum limit of current plan.</h2>
                                    <Typography sx={{ mb: 2 }} variant='body1'>To Create new database please Upgrade your Plan</Typography>
                                    <UpgradePlan app={true} />
                                </Grid>

                            </Container> :
<>
                            {!showAppDeployed && <Container sx={{ mt: 3 }}>


                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}

                                >


                                    {(startLoading ? (selectedDB === "mysql" ? true : false) : true) && <Grid item>
                                        <Zoom in={true}>
                                            <Paper onClick={() => {
                                                setselectedDB("mysql");
                                                setdbUserName("root");
                                                var temp = generateRandomName("mysql")
                                                setdbName(temp)
                                                setvolumePath(`/data/${temp}`)

                                            }} variant='outlined' sx={{ mt: 10, p: 1, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderBottom: selectedDB === "mysql" ? `4px solid ${theme.palette.primary.main}` : undefined, cursor: "pointer" }}>
                                                <img style={{ width: 100, height: 50, margin: 10 }} src={"https://upload.wikimedia.org/wikipedia/fr/6/62/MySQL.svg"} />
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"

                                                >
                                                    <Typography sx={{ mb: 2 }} variant='body2'>MySQL
                                                        {selectedDB === "mysql" && <Zoom in={true}>
                                                            <IconButton sx={{ p: 0, ml: 0.5 }}><CheckCircle color='primary' style={{ fontSize: "20px" }} /></IconButton>
                                                        </Zoom>}

                                                    </Typography>

                                                </Grid>
                                            </Paper>
                                        </Zoom>
                                    </Grid>}



                                    {(startLoading ? (selectedDB === "MariaDB" ? true : false) : true) && <Grid item>
                                        <Zoom in={true}>
                                            <Paper

                                                onClick={() => {
                                                    setselectedDB("MariaDB");
                                                    setdbUserName("root");
                                                    var temp = generateRandomName("maria")
                                                    setdbName(temp)
                                                    setvolumePath(`/data/${temp}`)
                                                }}


                                                variant='outlined' sx={{ mt: 10, p: 1, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderBottom: selectedDB === "MariaDB" ? `4px solid ${theme.palette.primary.main}` : undefined, cursor: "pointer" }}>
                                                <img style={{ width: 100, height: 50, margin: 10 }} src={"https://upload.wikimedia.org/wikipedia/commons/c/ca/MariaDB_colour_logo.svg"} />
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"

                                                >
                                                    <Typography sx={{ mb: 2 }} variant='body2'>MariaDB
                                                        {selectedDB === "MariaDB" && <Zoom in={true}>
                                                            <IconButton sx={{ p: 0, ml: 0.5 }}><CheckCircle color='primary' style={{ fontSize: "20px" }} /></IconButton>
                                                        </Zoom>}

                                                    </Typography>

                                                </Grid>
                                            </Paper>
                                        </Zoom>
                                    </Grid>}



                                    {(startLoading ? (selectedDB === "postgres" ? true : false) : true) && <Grid item>
                                        <Zoom in={true}>
                                            <Paper

                                                onClick={() => {
                                                    setselectedDB("postgres");
                                                    setdbUserName("postgres");
                                                    var temp = generateRandomName("postgres")
                                                    setdbName(temp)
                                                    setvolumePath(`/data/${temp}`)
                                                }}


                                                variant='outlined' sx={{ mt: 10, p: 1, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderBottom: selectedDB === "postgres" ? `4px solid ${theme.palette.primary.main}` : undefined, cursor: "pointer" }}>
                                                <img style={{ width: 100, height: 50, margin: 10 }} src={"https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg"} />
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"

                                                >
                                                    <Typography sx={{ mb: 2 }} variant='body2'>PostgreSQL
                                                        {selectedDB === "postgres" && <Zoom in={true}>
                                                            <IconButton sx={{ p: 0, ml: 0.5 }}><CheckCircle color='primary' style={{ fontSize: "20px" }} /></IconButton>
                                                        </Zoom>}

                                                    </Typography>

                                                </Grid>
                                            </Paper>
                                        </Zoom>
                                    </Grid>}



                                    {(startLoading ? (selectedDB === "MongoDB" ? true : false) : true) && <Grid item>
                                        <Zoom in={true}>
                                            <Paper

                                                onClick={() => {
                                                    setselectedDB("MongoDB");
                                                    setdbUserName("MongoDB");
                                                    var temp = generateRandomName("mongodb")
                                                    setdbName(temp)
                                                    setvolumePath(`/data/${temp}`)
                                                }}


                                                variant='outlined' sx={{ p: 1, position: "relative", mt: 10, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderBottom: selectedDB === "MongoDB" ? `4px solid ${theme.palette.primary.main}` : undefined, cursor: "pointer" }}>

                                                <img style={{ width: 100, height: 50, margin: 10 }} src={"https://upload.wikimedia.org/wikipedia/en/5/5a/MongoDB_Fores-Green.svg"} />
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"

                                                >
                                                    <Typography sx={{ mb: 2 }} variant='body2'>MongoDB
                                                        {selectedDB === "MongoDB" && <Zoom in={true}>
                                                            <IconButton sx={{ p: 0, ml: 0.5 }}><CheckCircle color='primary' style={{ fontSize: "20px" }} /></IconButton>
                                                        </Zoom>}

                                                    </Typography>

                                                </Grid>
                                            </Paper>
                                        </Zoom>
                                    </Grid>}



                                    {(startLoading ? (selectedDB === "Redis" ? true : false) : true) && <Grid item>
                                        <Zoom in={true}>
                                            <Paper

                                                onClick={() => {
                                                    setselectedDB("Redis");
                                                    setdbUserName("Redis");
                                                    var temp = generateRandomName("redis")
                                                    setdbName(temp)
                                                    setvolumePath(`/data/${temp}`)
                                                }}


                                                variant='outlined' sx={{ p: 1, position: "relative", mt: 10, borderRadius: "12px", boxShadow: "rgba(17, 12, 46, 0.04) 0px 20px 30px 0px", borderBottom: selectedDB === "Redis" ? `4px solid ${theme.palette.primary.main}` : undefined, cursor: "pointer" }}>

                                                <img style={{ width: 100, height: 50, margin: 10 }} src={"https://upload.wikimedia.org/wikipedia/commons/6/64/Logo-redis.svg"} />
                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"

                                                >
                                                    <Typography sx={{ mb: 2 }} variant='body2'>Redis
                                                        {selectedDB === "Redis" && <Zoom in={true}>
                                                            <IconButton sx={{ p: 0, ml: 0.5 }}><CheckCircle color='primary' style={{ fontSize: "20px" }} /></IconButton>
                                                        </Zoom>}

                                                    </Typography>

                                                </Grid>
                                            </Paper>
                                        </Zoom>
                                    </Grid>}





                                </Grid>
                                <br />
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center"
                                    spacing={2}

                                >


                                    {startLoading ? <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ my: 10 }}
                                    >

                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Box sx={{ m: 1, position: 'relative' }}>
                                                <Fab
                                                    aria-label="save"
                                                    color="primary"
                                                    sx={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 5px" }}
                                                >
                                                    <FaDatabase style={{ fontSize: "23px" }} />
                                                </Fab>

                                                <CircularProgress
                                                    size={68}
                                                    sx={{
                                                        color: "#00d7ff",
                                                        position: 'absolute',
                                                        top: -6,
                                                        left: -6,
                                                        zIndex: 1,
                                                    }}
                                                />

                                            </Box>
                                        </Box>
                                        <Typography sx={{ ml: 1 }} variant='h4'>
                                            {volumeLoad && "Creating Volume.."}
                                            {DPLoad && "Deploying..."}
                                            {resload && "Fetching details..."}
                                            {apploading && "Initailizing..."}
                                            {updateLoad && "Updating database..."}
                                        </Typography>
                                    </Grid> :
                                        <Box sx={{ mt: 2 }}>
                                            {(inputValue === "" ? true : showAdditionalDetails) && <Grid item >

                                                <Grid
                                                    container
                                                    direction="row"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    sx={{ mb: 2 }}
                                                >

                                                    {orgdata && (<>
                                                        {orgdata.organizationsandBusinessUnit.nodes && (<>
                                                            {orgdata.organizationsandBusinessUnit.nodes && <Autocomplete
                                                                id="combo-box-demo"
                                                                value={value}
                                                                inputValue={inputValue}
                                                                loading={loadorg}
                                                                size="small"
                                                                onInputChange={(event, newInputValue) => {
                                                                    setInputValue(newInputValue);
                                                                }}
                                                                onChange={(e, newValue) => {
                                                                    setValue(newValue);
                                                                }}
                                                                options={row}
                                                                getOptionLabel={(option) => option.name}
                                                                renderOption={(props, option) => (
                                                                    <Tooltip title={option.type === "true" ? "Default organization" : "Organization"}>
                                                                        <Box key={option.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                                            {option.name}
                                                                            {option.type === "true" ? <b style={{ color: "rgb(33, 150, 243)", border: "1px solid rgb(33, 150, 243)", background: "rgb(227, 242, 253)", marginLeft: "5px", padding: "2px 10px 2px 10px", borderRadius: "12px", fontSize: "12px" }}>Default</b> : <Chip sx={{ ml: 1 }} size='small' label={"Organization"} />}
                                                                        </Box>
                                                                    </Tooltip>
                                                                )}

                                                                style={{ width: 300, marginBottom: "10px" }}
                                                                renderInput={(params) => <TextField {...params} label={"Select Organization"} placeholder="Search Organization" variant="outlined" />}
                                                            />

                                                            }
                                                        </>)}
                                                    </>)}

                                                </Grid>
                                            </Grid>}
                                            {!showAdditionalDetails && inputValue !== "" &&
                                                <Box sx={{ mb: 2 }} onClick={() => setshowAdditionalDetails(true)}>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                    >
                                                        <Chip label={`DB size is ${volumeSize} Gi`} sx={{ mr: 1 }} color='info' size='small' />

                                                        <Typography variant='body2'>To Customize Size, Organization, Root User name etc.</Typography>
                                                        <Button sx={{ fontWeight: "bold" }} onClick={() => setshowAdditionalDetails(true)} size='small' endIcon={<MdModeEdit />}>Click here</Button>


                                                    </Grid></Box>}
                                        </Box>
                                    }





                                    {!startLoading && volumeSizeError && <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{ pb: 2 }}
                                    >

                                        {localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "free plan" && <Grid item>
                                            <Typography>Maximum size limit for  <span style={{ color: "#5d86f9" }}>{(localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName && JSON.parse(localStorage.getItem("access")).planName !== "free plan" ?
                                                JSON.parse(localStorage.getItem("access")).planName : "Hobby plan")}
                                                {localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "free plan" && "plan"}</span> is {volumeSizeLimit && volumeSizeLimit}
                                            </Typography>
                                        </Grid>}


                                    </Grid>}



                                </Grid>


                                {selectedDB && inputValue !== "" && <Box>

                                    {!startLoading && <Grid
                                        container
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                    >
                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >

                                                {showAdditionalDetails && <Grid item sm={6} lg={6} xl={6} xs={12}>
                                                    <TextField value={dbName}
                                                        helperText={apperror ? apperror.message : (nameValidation && "Use lowercase letters and - (hyphen sign) only up to 15 letters")}
                                                        error={nameValidation || apperror}
                                                        onChange={(e) => { setdbName(e.target.value); valuevalidateName(e); setvolumePath(`/data/${e.target.value}`) }} size="small" fullWidth label={"Database Name"} />
                                                </Grid>}


                                                {localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "free plan" && showAdditionalDetails &&
                                                    <Grid item sm={6} lg={6} xl={6} xs={12}>


                                                        <TextField value={volumeSize}

                                                            InputProps={{
                                                                inputProps: { min: 0 }
                                                            }}
                                                            type="number"
                                                            onInput={(e) => {

                                                                if (volumeSizeLimit && e.target.value <= volumeSizeLimit && e.target.value >= 0) {
                                                                    setvolumeSizeError(false)
                                                                    setvolumeSize(e.target.value)
                                                                }
                                                                else {
                                                                    setvolumeSizeError(true)
                                                                    setvolumeSize(e.target.value)
                                                                }


                                                            }}
                                                            error={volumeSizeError}
                                                            helperText={volumeSizeError && "Maximum limit exceed or Invalid format"}
                                                            size="small" margin="dense" label='Size (in Gibibyte)' placeholder='5' />
                                                    </Grid>}


                                                <Grid item sm={6} lg={6} xl={6} xs={12}>
                                                    <TextField type='password' value={dbPass} onChange={(e) => setdbPass(e.target.value)} size="small" fullWidth label={"Database Root Password"} />

                                                </Grid>


                                            </Grid>
                                        </Grid>

                                        <Grid item>
                                            <Grid
                                                container
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >


                                                {showAdditionalDetails && <Grid item sm={6} lg={6} xl={6} xs={12}>
                                                    <TextField disabled={selectedDB !== "MongoDB"} value={dbUserName} onChange={(e) => setdbUserName(e.target.value)} size="small" fullWidth label={"Database Root User Name"} />
                                                </Grid>}
                                                {showAdditionalDetails && localStorage.getItem("access") && JSON.parse(localStorage.getItem("access")).planName !== "free plan" &&
                                                    <Grid item sm={6} lg={6} xl={6} xs={12}>

                                                        <TextField value={volumePath} error={volumePath === "" && true} helperText={volumePath === "" && "Volume Path cannot be empty"} onChange={(e) => setvolumePath(e.target.value)} size="small" margin='dense' label="Volume Path" placeholder='/data/database name' />
                                                    </Grid>}


                                                <Grid item sm={6} lg={6} xl={6} xs={12}>
                                                    <Button fullWidth disabled={dbPass && dbName && dbUserName && value ? false : true} sx={{ minWidth: "245px" }} variant='contained' onClick={handleCreateDBapp}> Create database </Button>
                                                </Grid>
                                            </Grid>






                                        </Grid>

                                    </Grid>}

                                </Box>}

                            </Container>}</>}
                    </>}
            </Dialog>





            {loading ?
                <div>
                    <Skeleton style={{ margin: "10px" }} />
                    <Skeleton variant="rectangular" height={100} style={{ margin: "10px" }} />
                </div> :
                <div>
                    {data && rows.length === 0 &&
                        <div style={{ margin: "10px", color: "grey" }}>
                            <Fade in={true}
                                style={{ transformOrigin: '0 0 0' }}
                                {...(true ? { timeout: 500 } : {})}>
                                <div>
                                    <EmptyPageRenderCustom
                                        mainTitle="Create a database"
                                        secondaryTitle="Establish a structured and organized database and deploy efficiently."
                                        primaryButtonAction={handleClickOpen}
                                        primaryButtonText={"Create database"}
                                        learnMoreLink="https://docs.nife.io/docs/UI%20Guide/database"
                                        iframeLink="https://konvey.s3.amazonaws.com/videos/video_171407198.mp4"
                                        imageListTitle={"Supported database"}
                                        imageList={[
                                            { "name": "MySQL", "image": "https://upload.wikimedia.org/wikipedia/fr/6/62/MySQL.svg" },
                                            { "name": "Postreg", "image": "https://upload.wikimedia.org/wikipedia/commons/2/29/Postgresql_elephant.svg" },
                                            { "name": "Maria", "image": "https://upload.wikimedia.org/wikipedia/commons/c/ca/MariaDB_colour_logo.svg" },
                                            { "name": "Mongo", "image": "https://upload.wikimedia.org/wikipedia/en/5/5a/MongoDB_Fores-Green.svg" },
                                            { "name": "Redis", "image": "https://upload.wikimedia.org/wikipedia/commons/6/64/Logo-redis.svg" }]}

                                    />
                                </div>

                            </Fade>
                        </div>}
                    {data.apps.nodes && rows.length > 0 &&
                        <div>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline"
                                sx={{ mb: 2 }}
                            >


                                <TextField
                                    value={searched}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {searched !== "" &&
                                                    <IconButton onClick={() => { setSearched("") }}>
                                                        <Close />
                                                    </IconButton>}
                                            </InputAdornment>
                                        ),
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        )
                                    }}

                                    size={"small"}
                                    type='text'

                                    placeholder='Search for Databases'
                                    onChange={(e) => setSearched(e.target.value)}
                                />

                                <Button onClick={handleClickOpen} disabled={false} startIcon={<RiAddLine />} variant="contained" disableElevation style={{ boxShadow: "rgba(58, 59, 69, 0.15) 0px 0.15rem 1.75rem 0px", borderRadius: "24px" }}>Create Database</Button>

                            </Grid>
                            <Paper variant='outlined' style={{ boxShadow: "rgba(17, 12, 46, 0.05) 0px 30px 50px 05px", borderRadius: "12px" }}>


                                <Fade in={true}
                                    style={{ transformOrigin: '0 0 0' }}
                                    {...(true ? { timeout: 500 } : {})}>
                                    <div>
                                        {data.apps.nodes && data.apps.nodes.length > 0 && rows.length > 0 && <Box style={{ width: "100%", margin: "0px 0px 0px 0px", padding: "10px" }}>
                                            {data.apps.nodes && rows && <DataGrid
                                                dens

                                                onSelectionModelChange={(ids) => {
                                                    const selectedIDs = new Set(ids);
                                                    const selectedRows = data.apps.nodes.filter((row) =>
                                                        selectedIDs.has(row.id),
                                                    );

                                                    // setSelectedRows(selectedRows);
                                                }}
                                                rowHeight={70}
                                                autoHeight={true}
                                                density={"compact"}
                                                scrollbarSize={1}
                                                // className={classes.root}
                                                rows={rows}
                                                disableColumnFilter={true}
                                                columns={columns}
                                                loading={loading}
                                                pageSize={10}
                                                onCellClick={handleCellClick}
                                                style={{ border: "2px" }}
                                            />
                                            }


                                        </Box>
                                        }
                                    </div>
                                </Fade>

                            </Paper>
                        </div>}
                </div>}

       

        </div>
    )
}
